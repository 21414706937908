import { Button, Form, Select, Table, Row, Col, Layout, Divider } from 'antd';
import React from 'react';
import calculateWish from './WishCalculation';
const { Header, Content, Footer } = Layout;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

function initNumberOptions(maxNumber) {
  const options = [];
  for (let i = 0; i < maxNumber; i++) {
    const value = i.toString();
    options.push({
      label: value,
      value,
      disabled: false
    });
  }
  return options;
}

const roleTriedOptions = initNumberOptions(90);
const weaponTriedOptions = initNumberOptions(80);
const weaponCountOptions = initNumberOptions(6);
const maxUpCount = 7;

function initColumns() {
  const columns = [{ title: "抽数", dataIndex: "nTry", key: "nTry", width: 70, fixed: "left"}, { title: "只抽武器抽中概率(%)", width: 100, dataIndex: "0", key: "0" }];
  for (let i = 1; i <= maxUpCount; i++) {
    const value = i.toString();
    columns.push({ title: "抽武器和" + (i - 1).toString() + "命抽中概率(%)", dataIndex: value, key: value , width: 100});
  }
  return columns;
}

function convertWishResultToTableData(wishResult) {
  const nRows = Math.max(...wishResult.map(arr => arr.length));
  const dataSource = [];
  for (let i = 0; i < nRows; i++) {
    const row = {
      key: (i + 1).toString(),
      nTry: (i + 1).toString(),
    }
    for (let j = 0; j <= maxUpCount; j++) {
      row[j.toString()] = (wishResult[j][i] * 100.0).toFixed(4);
    }
    dataSource.push(row);
  }
  return dataSource;
}

const columns = initColumns();

class App extends React.Component {
  state = {
    tableData: [],
    tip: "计算可能耗时几秒到几十秒，期间浏览器可能卡顿。"
  }

  formRef = React.createRef();
  onSubmit = (values) => {
    this.setState({ tableData: [], tip: "计算中" });
    if (this.calculateTimer) {
      clearTimeout(this.calculateTimer);
    }
    this.calculateTimer = setTimeout(() => {
      const weaponCount = parseInt(values.weaponCount);
      const roleMissed = (values.roleMissed === 'true')
      const roleTried = parseInt(values.roleTried);
      const weaponMissed = (values.weaponMissed === 'true')
      const weaponTried = parseInt(values.weaponTried);
      const dinguiCount = parseInt(values.dinguiCount);
      const wishResult = calculateWish(7, weaponCount, roleTried, roleMissed, weaponTried, weaponMissed, dinguiCount);
      const dataSource = convertWishResultToTableData(wishResult);
      console.log(roleMissed, roleTried, wishResult);
      this.setState({ tableData: dataSource, tip: "计算完成" });
    }, 1);
  };

  render() {
    return (
      <Layout style={{ minHeight: '100vh', overflow:"inherit" }}>
        <Header>
          <h1 style={{ color: "white" }}>原神活动祈愿概率计算</h1>
        </Header>

        <Content style={{ margin: "2%", overflow:"inherit" }}>
          <p>原神祈愿概率模型来自<a href="https://www.bilibili.com/read/cv10468091">一棵平衡树发表在Bilibili的文章</a>。计算结果仅供参考，原神官方未公布详细的每次祈愿的详细概率细节。</p>
          <Divider/>
          <Form {...layout} form={this.form} ref={this.formRef} name="control-ref" onFinish={this.onSubmit} labelWrap
            initialValues={{ "weaponCount": "0", "roleMissed": "false", "roleTried": "0", "weaponMissed": "false", "weaponTried": "0", "dinguiCount": "0" }}>
            <Row justify="start">
              <Col span={8}>
                <Form.Item
                  name="weaponCount"
                  label="定轨武器抽取精炼数"
                >
                  <Select
                    options={weaponCountOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={8}>
                <Form.Item
                  name="roleMissed"
                  label="角色保底情况"
                >
                  <Select>
                    <Option value="false">小保底</Option>
                    <Option value="true">大保底</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="roleTried"
                  label="角色已垫抽数"
                >
                  <Select
                    options={roleTriedOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={8}>
                <Form.Item
                  name="weaponMissed"
                  label="武器保底情况"
                >
                  <Select>
                    <Option value="false">小保底</Option>
                    <Option value="true">大保底</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="dinguiCount"
                  label="已积攒定轨值"
                >
                  <Select>
                    <Option value="0">0</Option>
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="weaponTried"
                  label="武器已垫抽数"
                >
                  <Select
                    options={weaponTriedOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 16,
              }}
            >
              <Row justify="start">
                <Button type="primary" htmlType="submit">
                  开始计算
                </Button>
                <p style={{ padding: "10px", color: "red" }}>{this.state.tip}</p>
              </Row>
            </Form.Item>
          </Form>
          <Table 
            locale={{ emptyText: '待计算' }}
            dataSource={this.state.tableData} 
            columns={columns} 
            pagination={false} 
            scroll={{ x: "90%", y: 600, scrollToFirstRowOnChange: true }}> 
            sticky={true}
          </Table>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    );
  }
}

export default App;