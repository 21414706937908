const maxGetGoldRoleCount = 90;
const maxGetGoldWeaponCount = 80;

//抽第n次出金角色的概率
function goldRoleAtNProbability(n) {
    if (n <= 73) {
        return 0.006;
    }
    if (n === maxGetGoldRoleCount) {
        return 1.0;
    }
    if (n > maxGetGoldRoleCount) {
        throw new Error("n > maxGetGoldRoleCount");
    }
    return 0.006 + 0.06 * (n - 73)
}

//抽第n次出金角色的概率
function goldWeaponAtNProbability(n) {
    if (n <= 62) {
        return 0.007;
    }
    if (n <= 71) {
        return 0.007 + 0.07 * (n - 62);
    }
    if (n === maxGetGoldWeaponCount) {
        return 1.0;
    }
    if (n > maxGetGoldWeaponCount) {
        throw new Error("n > maxGetGoldWeaponCount");
    }
    return 0.637 + 0.035 * (n - 71);
}

//计算恰好在第n发出金角色/武器的概率，存到数组里
function initTryNGetGoldProbability(funcGoldAtN, maxGetGoldCount) {
    const ret = [];
    for (let nTried = 0; nTried < maxGetGoldCount; nTried++) {
        const pArray = [];
        let p = 1.0;
        for (let i = 1; i <= maxGetGoldCount - nTried; i++) {
            const pCurrent = funcGoldAtN(nTried + i);
            pArray.push(p * pCurrent);
            p *= 1 - pCurrent
        }
        ret.push(pArray);
    }
    return ret;
}

//计算恰好在第n发出金角色的概率，存到数组里
function initTryNGetGoldRoleProbability() {
    return initTryNGetGoldProbability(goldRoleAtNProbability, maxGetGoldRoleCount);
}

function initTryNGetGoldWeaponProbability() {
    return initTryNGetGoldProbability(goldWeaponAtNProbability, maxGetGoldWeaponCount);
}

const goldRoleAtNProbabilityArray = initTryNGetGoldRoleProbability();
const goldWeaponAtNProbabilityArray = initTryNGetGoldWeaponProbability();

//恰好在第n发出金角色的概率
function tryNGetGoldRoleProbability(n, nTried = 0) {
    if (n + nTried > maxGetGoldRoleCount) {
        return 0.0;
    }
    return goldRoleAtNProbabilityArray[nTried][n - 1];
}

//恰好在第n发出金武器的概率
function tryNGetGoldWeaponProbability(n, nTried = 0) {
    if (n + nTried > maxGetGoldWeaponCount) {
        return 0.0;
    }
    return goldWeaponAtNProbabilityArray[nTried][n - 1];
}

//恰好在第n发出up角色的概率, n_tried: 已抽抽数，  missed： 是否已歪一次
function tryNGetUpRoleProbability(n, nTried = 0, missed = false) {
    if ((nTried + n > maxGetGoldRoleCount * 2) || (missed && nTried + n > maxGetGoldRoleCount)) {
        return 0.0;
    }
    if (missed) {
        return tryNGetGoldRoleProbability(n, nTried);
    }
    let p = 0.0;
    for (let i = 1; i < n; i++) {
        p += tryNGetGoldRoleProbability(i, nTried) * tryNGetGoldRoleProbability(n - i) * 0.5;
    }
    return p + tryNGetGoldRoleProbability(n, nTried) * 0.5;
}

//1次定轨值后，第n发恰好抽中定轨武器的概率
function tryNGetUpWeaponProbabilityWithOneDinggui(n, missed) {
    if (n > maxGetGoldWeaponCount * 2) {
        return 0.0;
    }
    const hitP = missed ? 0.5 : 0.5 * 0.75;
    let p = 0.0;
    //抽到第i发歪了另一把或常驻，然后抽到第n发中
    for (let i = 1; i < n; i++) {
        p += tryNGetGoldWeaponProbability(i, 0) * tryNGetGoldWeaponProbability(n - i) * (1.0 - hitP);
    }
    return p + tryNGetGoldWeaponProbability(n, 0) * hitP;
}

function initTryNGetUpWeaponProbabilityWithOneDingguiArray(missed) {
    const ret = [];
    for (let i = 1; i <= maxGetGoldWeaponCount * 2; i++) {
        const p = tryNGetUpWeaponProbabilityWithOneDinggui(i, missed);
        ret.push(p);
    }
    return ret;
}

const tryNGetUpWeaponProbabilityWithOneDingguiNotMissedArray = initTryNGetUpWeaponProbabilityWithOneDingguiArray(false);
const tryNGetUpWeaponProbabilityWithOneDingguiMissedArray = initTryNGetUpWeaponProbabilityWithOneDingguiArray(true);

function queryOneDingguiCache(n, missed) {
    if (n > maxGetGoldWeaponCount * 2) {
        return 0.0;
    }
    if (missed) {
        return tryNGetUpWeaponProbabilityWithOneDingguiMissedArray[n - 1];
    }
    return tryNGetUpWeaponProbabilityWithOneDingguiNotMissedArray[n - 1];
}

//恰好在第n发出up武器的概率, n_tried: 已抽抽数，  missed： 是否已歪一次
function tryNGetUpWeaponProbability(n, nTried = 0, missed = false, dingguiCount = 0) {
    if (nTried + n > maxGetGoldWeaponCount * (3 - dingguiCount)) {
        return 0.0;
    }
    if (dingguiCount === 2) {
        return tryNGetGoldWeaponProbability(n, nTried);
    }
    if (dingguiCount === 1) {
        return queryOneDingguiCache(n, missed);
    }
    if (dingguiCount > 2) {
        throw new Error("dingguiCount > 2");
    }
    //定轨
    let p = 0.0;
    //抽到第i发中歪了另一把up，然后抽到第n发中
    for (let i = 1; i < n; i++) {
        const hitP = missed ? 0.5 : 0.75 * 0.5
        p += tryNGetGoldWeaponProbability(i, nTried) * queryOneDingguiCache(n - i, false) * hitP;
    }
    //抽到第i发歪了常驻，然后抽到第n发中
    if (!missed) {
        for (let i = 1; i < n; i++) {
            const hitP = 1 - 0.75;
            p += tryNGetGoldWeaponProbability(i, nTried) * queryOneDingguiCache(n - i, true) * hitP;
        }
    }
    //没歪，第n发直接中
    const hitP = missed ? 0.5 : 0.5 * 0.75;
    p += tryNGetGoldWeaponProbability(n, nTried) * hitP;
    return p;
}

function tryNGetMUpRoleFromCache(cache, n, m) {
    if (n > m * maxGetGoldRoleCount * 2) {
        return 0.0;
    }
    return cache[m - 1][n - 1];
}

function initTryNGetMUpRoleProbabilityArray(maxM) {
    const ret = [];
    let pArray = [];
    for (let n = 1; n <= maxGetGoldRoleCount * 2; n++) {
        pArray.push(tryNGetUpRoleProbability(n));
    }
    ret.push(pArray);
    for (let m = 2; m <= maxM; m++) {
        pArray = [];
        for (let n = 1; n <= maxGetGoldRoleCount * 2 * m; n++) {
            let p = 0.0;
            for (let i = m - 1; i < n; i++) {
                p += tryNGetMUpRoleFromCache(ret, i, m - 1) * tryNGetMUpRoleFromCache(ret, n - i, 1);
            }
            pArray.push(p);
        }
        ret.push(pArray);
    }
    return ret;
}

function tryNGetMUpWeaponFromCache(cache, n, m) {
    if (n > m * maxGetGoldWeaponCount * 3) {
        return 0.0;
    }
    return cache[m - 1][n - 1];
}

function initTryNGetMUpWeaponProbabilityArray(maxM) {
    const ret = [];
    let pArray = [];
    for (let n = 1; n <= maxGetGoldWeaponCount * 3; n++) {
        pArray.push(tryNGetUpWeaponProbability(n));
    }
    ret.push(pArray);
    for (let m = 2; m <= maxM; m++) {
        pArray = [];
        for (let n = 1; n <= maxGetGoldWeaponCount * 3 * m; n++) {
            let p = 0.0;
            for (let i = m - 1; i < n; i++) {
                p += tryNGetMUpWeaponFromCache(ret, i, m - 1) * tryNGetMUpWeaponFromCache(ret, n - i, 1);
            }
            pArray.push(p);
        }
        ret.push(pArray);
    }
    return ret;
}

const tryNGetMUpRoleProbabilityArray = initTryNGetMUpRoleProbabilityArray(7);
const tryNGetMUpWeaponProbabilityArray = initTryNGetMUpWeaponProbabilityArray(7);

function tryNGetMUpRoleProbability(n, m) {
    const arr = tryNGetMUpRoleProbabilityArray[m - 1];
    if (arr.length < n) {
        return 0.0;
    }
    return arr[n - 1];
}

function tryNGetMUpWeaponProbability(n, m) {
    const arr = tryNGetMUpWeaponProbabilityArray[m - 1];
    if (arr.length < n) {
        return 0.0;
    }
    return arr[n - 1];
}

function tryNGetMUpRoleProbabilityWithInit(n, m, nTried, missed) {
    if (m === 1) {
        return tryNGetUpRoleProbability(n, nTried, missed);
    }
    let p = 0.0;
    for (let i = 1; i < n + 2 - m; i++) {
        p += tryNGetUpRoleProbability(i, nTried, missed) * tryNGetMUpRoleProbability(n - i, m - 1);
    }
    return p;
}

function tryNGetMUpWeaponProbabilityWithInit(n, m, nTried, missed, dingguiCount) {
    if (m === 1) {
        return tryNGetUpWeaponProbability(n, nTried, missed, dingguiCount);
    }
    let p = 0.0;
    for (let i = 1; i < n + 2 - m; i++) {
        p += tryNGetUpWeaponProbability(i, nTried, missed, dingguiCount) * tryNGetMUpWeaponProbability(n - i, m - 1);
    }
    return p;
}

function buildTryNGetMUpRoleArray(m, nTried, missed) {
    const ret = [];
    const maxTryCount = m * maxGetGoldRoleCount * 2 - nTried - (missed ? maxGetGoldRoleCount : 0);
    for (let i = 1; i <= maxTryCount; i++) {
        ret.push(tryNGetMUpRoleProbabilityWithInit(i, m, nTried, missed));
    }
    return ret;
}

function buildTryNGetMUpWeaponArray(m, nTried, missed, dingguiCount) {
    const ret = [];
    const maxTryCount = m * maxGetGoldWeaponCount * 3 - nTried - dingguiCount * maxGetGoldWeaponCount;
    for (let i = 1; i <= maxTryCount; i++) {
        ret.push(tryNGetMUpWeaponProbabilityWithInit(i, m, nTried, missed, dingguiCount));
    }
    return ret;
}

function queryProbabilityCache(cache, n) {
    if (n - 1 >= cache.length) {
        return 0.0;
    }
    return cache[n - 1];
}

//恰好n抽拿到角色和武器的概率
function roleAndWeaponProbability(n, pRoles, pWeapons) {
    let p = 0.0;
    for (let i = 1; i < n; i++) {
        p += queryProbabilityCache(pRoles, i) * queryProbabilityCache(pWeapons, n - i);
    }
    return p;
}

const calculateWish = function (maxRoles, nWeapons, roleTried, roleMissed, weaponTried, weaponMissed, dinguiCount) {
    const pWeapons = nWeapons === 0 ? null : buildTryNGetMUpWeaponArray(nWeapons, weaponTried, weaponMissed, dinguiCount);
    const ret = [];
    const maxTryCount = maxRoles * 2 * maxGetGoldRoleCount - (roleMissed ? maxGetGoldRoleCount : 0) - roleTried + (nWeapons * 3 - dinguiCount) * maxGetGoldWeaponCount
    for (let m = 0; m <= maxRoles; m++) {
        let s = 0.0;
        const pArray = [];
        const pRoles = buildTryNGetMUpRoleArray(m, roleTried, roleMissed);
        for (let i = 1; i <= maxTryCount; i++) {
            let p = 0.0;
            if (m === 0) {
                if (nWeapons === 0) {
                    pArray.push(1.0);
                    continue;
                }
                p = queryProbabilityCache(pWeapons, i);
            } else {
                if (nWeapons === 0) {
                    p = queryProbabilityCache(pRoles, i);
                } else {
                    p = roleAndWeaponProbability(i, pRoles, pWeapons);
                }
            }
            s += p;
            pArray.push(s);
        }
        ret.push(pArray)
    }
    return ret;
}

export default calculateWish;